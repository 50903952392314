.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > td {
  background: #fafafa;
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #fafafa;
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
  padding-top: 15px;
}

.ant-table-wrapper tr.ant-table-expanded-row:hover > td {
  background: #fafafa;
}

.ant-table-wrapper tr.ant-table-expanded-row > td {
  background: #fafafa;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-typography {
  font-size: 15px;
}

.ant-table-thead > tr > th,
tr.ant-table-expanded-row {
  background: #fafafa !important;
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
  padding-top: 30px;
}
