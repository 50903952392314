.ant-select .ant-select-arrow {
  top: 50%;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 37px;
  padding: 0 11px;
  align-items: center;
  font-size: 16px;
}
