.time-picker {
  text-align: center;
  background-color: rgb(255, 255, 255);
  font-size: 18px;
  caret-color: transparent;
  width: 140px;
  margin-bottom: 10px;
  border: 1px solid #1d1c1c96;
  letter-spacing: 0.5px;
  padding: 5px 8px;
  outline-style: none;
  border-radius: 4px;
}

.time-picker:hover {
  border-color: hsl(202, 100%, 50%);
}

.btn-box {
  display: inline-block;
  margin-left: 10px;
  margin-top: 2.5px;
}

.ant-select .ant-select-arrow {
  top: 40%;
}
