.header {
  font-size: 30px;
  margin: 10px 10px 10px 0;
  text-decoration: underline;
}

.spinner {
  text-align: center;
  margin-top: 20px;
}

.kalgraph {
  max-width: 100%;
  height: auto;
}
