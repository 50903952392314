.zip-viewer {
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 24px;
  color: #333;
}

.primary-directory {
  margin-bottom: 20px;
}

.primary-directory-header {
  font-size: 20px;
  color: #007bff;
  margin-bottom: 10px;
}

.primary-directory-container {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  background-color: #f5f5f5;
  max-height: 400px;
  overflow-y: auto;
}

.directory {
  margin-left: 20px;
  margin-bottom: 10px;
}

.directory-header {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.directory-content {
  margin-left: 20px;
}

.file-item {
  margin-left: 20px;
  padding: 5px;
  padding-left: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 5px;
}

strong {
  font-size: 18px;
  color: #007bff;
}
