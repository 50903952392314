.csv-viewer {
  padding-top: 12px;
}

.csv-viewer .ant-table {
  overflow-x: auto;
  width: 100%;
}

.csv-viewer .ant-table-cell {
  white-space: normal !important;
  word-wrap: break-word !important;
  max-width: 160px;
}

.csv-viewer-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  height: 100%;
}
