.field-box {
  margin: 20px 0px 20px 0px;
}

.ant-input {
  border-radius: 0px;
}

.ant-input-affix-wrapper-lg {
  border-radius: 0px;
}
